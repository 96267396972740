import Carousel from "react-bootstrap/Carousel";

import LanguageContext from "./../store/language-context";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useContext } from "react";
import ReactWeather, { useOpenWeather } from "react-open-weather";

function HomeCarousel() {
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: "1c588b9fa2886f1983b50d2648c0c90c",
    lat: "48.137154",
    lon: "11.576124",
    lang: "en",
    unit: "metric", // values are (metric, standard, imperial)
  });
  return (
    <>
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./assets/images/slider/Δ6.DJI_0868.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>{t("Ψηφιακή Περιήγηση")}</h3>
            <p>
              {t(
                "στις Διαδρομές Περιβαλλοντικού και Αγροτικού Ενδιαφέροντος της Φολεγάνδρου"
              )}
              <br />
              <br />
              <a href="/home">
                {t("Εξερεύνηση")}{" "}
                <span className="fa-solid fa-arrow-right"></span>
              </a>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100  "
            src="assets/images/slider/Δ1.DSC02285.jpg"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3>{t("Καλώς ήρθατε στη Φολέγανδρο")}</h3>
            <p>
              {t("Περιηγηθείτε στα Μονοπάτια της Φολεγάνδρου")}
              <br />
              {t("Εξερευνήστε την άγρια ομορφιά του νησιού")}
              <br />
              {t(
                "Ανακαλύψτε τις παραμυθένιες παραλίες και τα μοναδικά αξιοθέατα της"
              )}
              <br />
              <br />
              <a href="/home">
                {t("Εξερεύνηση")}{" "}
                <span className="fa-solid fa-arrow-right"></span>
              </a>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100  "
            src="assets/images/slider/Δ2.DSC02499.jpg"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>{t("Καλώς ήρθατε στη Φολέγανδρο")}</h3>
            <p>
              {t("Περιηγηθείτε στα Μονοπάτια της Φολεγάνδρου")}
              <br />
              {t("Εξερευνήστε την άγρια ομορφιά του νησιού")}
              <br />
              {t(
                "Ανακαλύψτε τις παραμυθένιες παραλίες και τα μοναδικά αξιοθέατα της"
              )}
              <br />
              <br />
              <a href="/home">
                {t("Εξερεύνηση")}{" "}
                <span className="fa-solid fa-arrow-right"></span>
              </a>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 "
            src="assets/images/slider/Δ5.DSC01768.jpg"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>{t("Καλώς ήρθατε στη Φολέγανδρο")}</h3>
            <p>
              {t("Περιηγηθείτε στα Μονοπάτια της Φολεγάνδρου")}
              <br />
              {t("Εξερευνήστε την άγρια ομορφιά του νησιού")}
              <br />
              {t(
                "Ανακαλύψτε τις παραμυθένιες παραλίες και τα μοναδικά αξιοθέατα της"
              )}
              <br />
              <br />
              <a href="/home">
                {t("Εξερεύνηση")}{" "}
                <span className="fa-solid fa-arrow-right"></span>
              </a>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <ReactWeather
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        lang="en"
        locationLabel="Munich"
        unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
        showForecast
      />
    </>
  );
}

export default HomeCarousel;
