import React, { Fragment, useContext } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
} from "reactstrap";
import { Loading } from "./LoadingComponent";
import { baseUrl } from "../shared/baseUrl";
import { FadeTransform } from "react-animation-components";
import HomeCarousel from "./HomeCarousel";
import SuggestedRoutes from "./SuggestedRoutes/SuggestedRoutes";
import Footer from "./FooterComponent.js";
import LanguageContext from "./../store/language-context";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function Home() {
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="fullslider">
        <HomeCarousel />

        <div className="container section2">
          <h2>{t("Ανακαλύψτε τη Φολέγανδρο")}</h2>
          <Row className="d-flex flex-row justify-content-start">
            <Col xs={12} lg={6}>
              <div className="intr">
                {languageCtx.languageCode === "el" ? (
                  <span>
                    <p>
                      Η Φολέγανδρος είναι ένα βραχώδες νησί με έδαφος άγονο. Ο
                      χειμώνας δίνει λιγοστές βροχές και οι θυελλώδεις άνεμοια
                      ποτελούν ένα συχνό καιρικό φαινόμενο. Φυτά, ζώα και
                      άνθρωποι μοχθούν καθημερινά για να καταφέρουν να ζήσουν.{" "}
                    </p>
                    <p>
                      Οι Φολεγανδρίτες, ακούραστοι εργάτες της γης, είναι
                      άνθρωποι φιλήσυχοι, ευγενικοί και φιλόξενοι παρόλο που ο
                      τόπος τους είναι τραχύς.{" "}
                    </p>
                    <p>
                      Τα μονοπάτια και οι λιθόστρωτοι οδοί της Φολεγάνδρου
                      αποτελούσαν από την αρχαιότητα το βασικότερο σύστημα
                      πρόσβασης των κατοίκων μεταξύ των οικισμών και των χώρων
                      αγροτικής και κτηνοτροφικής δραστηριότητας.
                    </p>
                    <p>
                      Στο παρόν, η περιήγηση σε αυτά φιλοδοξεί να αποτελέσει ένα
                      δίδαγμα σχετικά με τη σημαντικότητα της λιτής αφθονίας και
                      να αναδείξει έναν απέριττο τρόπο ζωής που σέβεται το
                      περιβάλλον και το συνάνθρωπο.
                    </p>
                  </span>
                ) : (
                  ""
                )}
                {languageCtx.languageCode === "en" ? (
                  <span>
                    <p>
                      Folegandros is a rocky island with barren soil. Winter
                      gives little rain and stormy winds are a frequent weather
                      phenomenon. Plants, animals and people struggle every day
                      to survive.{" "}
                    </p>
                    <p>
                      Residents of Folegandros, tireless workers of the land,
                      are peaceful, kind and hospitable people even though their
                      place is rough.
                    </p>
                    <p>
                      The paths and stone-paved streets of Folegandros have been
                      the most basic access system for the inhabitants between
                      the settlements and the areas of agricultural and
                      livestock activity since ancient times.
                    </p>

                    <p>
                      In the present, the tour of them aspires to be a lesson
                      about the importance of frugal abundance and to highlight
                      a spare lifestyle that respects the environment and fellow
                      human beings.
                    </p>
                  </span>
                ) : (
                  ""
                )}
                {languageCtx.languageCode === "fr" ? (
                  <span>
                    <p>
                      Folegandros est une île rocheuse au sol aride. L'hiver
                      donne peu de pluie et les vents orageux sont un phénomène
                      météorologique fréquent. Les plantes, les animaux et les
                      humains luttent chaque jour pour survivre.{" "}
                    </p>
                    <p>
                      Les habitants de Folegandros, travailleurs infatigables de
                      la terre, sont des gens paisibles, gentils et hospitaliers
                      même si leur lieu est difficile.
                    </p>
                    <p>
                      Les chemins et les rues pavées de Folegandros ont été le
                      système d'accès le plus élémentaire pour les habitants
                      entre les colonies et les zones d'activité agricole et
                      d'élevage depuis l'Antiquité.
                    </p>

                    <p>
                      Dans le présent, la visite d'eux aspire à être une leçon
                      sur l'importance de l'abondance frugale et à mettre en
                      lumière un mode de vie sobre qui respecte l'environnement
                      et les autres êtres humains.
                    </p>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Col>

            <Col xs={12} lg={6}>
              <img
                src="https://folegandros.mitos.cityguideplatform.com/instance_images/folegandros/TGCLaK9lPFkUlmRzRzemG2qGDrKAadqjXnYb5se7.jpg"
                alt="folegradros natural port"
              />
            </Col>
          </Row>
        </div>
        <div className="container section3">
          <div
            style={{
              maxWidth: 1200,
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 64,
            }}
          >
            <div className="container section4">
              <h2>{t("Προτεινόμενες διαδρομές")}</h2>
              <div className="row align-items-start">
                <div className="col-12 col-sm-12 col-m-12 col-lg-12">
                  <SuggestedRoutes />
                </div>
              </div>
            </div>
            <div className="container section5">
              <div className="row align-items-start">
                <div className="col-12 col-sm-12 col-m-6 col-lg-6">
                  <h5>
                    {t("Οργάνωσε")} <br />
                    {t("το δικό σου ταξίδι")}
                  </h5>
                </div>
                <div className="col-12 col-sm-12 col-m-6 col-lg-6">
                  <img
                    src="assets/images/bonbon-line.png"
                    alt="folegandros line"
                  />
                  <br />
                </div>
              </div>
              <div className="tripbtn">
                <a href="/trip">{t("Δημιουργία")}</a>
              </div>
            </div>

            <div className="container section5a">
              <div className="row align-items-start">
                <div className="col-12 col-sm-12 col-m-6 col-lg-6 s1 appsc1">
                  <h5>
                    {t("Κατεβάστε την εφαρμογή στο τηλέφωνο ή στο tablet")}{" "}
                  </h5>
                </div>
                <div className="col-12 col-sm-12 col-m-6 col-lg-6 s1 appsc">
                  <a
                    href="https://apps.apple.com/app/id6443589662"
                    target="_blank"
                  >
                    <img src="assets/images/ios.png" alt="folegandros line" />{" "}
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=gr.dotsoft.cityguide.folegandros"
                    target="_blank"
                  >
                    <img
                      src="assets/images/android.png"
                      alt="folegandros line"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default Home;
