import React, { Component } from 'react';
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem,Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useState, useContext, Fragment, useEffect } from "react";

import LanguageContext from "./../store/language-context";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AuthContext from "./../store/auth-context";

const Header = (props) => {
    const languageCtx = useContext(LanguageContext);
    const currentLanguageCode = "en";
    const {t} = useTranslation();
    const [language, setLanguage] = useState("en");
    const authCtx = useContext(AuthContext);
  
    const handleLangChange = evt => {
      const lang = evt.target.value;
      console.log(lang);
      setLanguage(lang);
      i18next.changeLanguage(lang);
      languageCtx.languageCodeHandler(lang);
    };
    const [isNavExpanded, setIsNavExpanded] = useState(false)

    return(
        <nav className="navigation">
            <a href="/" className="logo"><img src="/assets/images/logo.png" className="logoimg" alt="folegandros logo" /></a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
        {/* hamburger svg code... */}
        <img src="/assets/images/icons-menu.png" alt="folegandros logo" />
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <a href="/home">{t("Αρχική")}</a>
          </li>
          <li>
            <a href="/routes">{t("Διαδρομές")}</a>
          </li>
          <li>
            <a href="/information">{t("Πληροφορίες")}</a>
          </li>
          {authCtx.isLoggedIn==false &&
          <>
          <li className="usermob">
            <span className="loginbm">
            <a href="/account/sign-up"><Button >{t("Εγγραφή")}</Button></a>
           </span>
          </li>
          <li className="usermob">
            <span className="loginb1m">
              <a href="/account/login"><Button className="btn1"> {t("Είσοδος")}</Button></a>
            </span>   
          </li>
          </>
          }
             {authCtx.isLoggedIn==true &&
          <>
          <li className="usermob">
            <span className="loginbm">
            <a href="/trip"><Button >{t("Τα ταξίδια μου")}</Button></a>
           </span>
          </li>
          <li className="usermob">
            <span className="loginb1m">
              <a href="/account/login"><Button className="btn1"> {t("Αποσύνδεση")}</Button></a>
            </span>   
          </li>
          </>
          }
        </ul>
      </div>
      {authCtx.isLoggedIn==false &&
      <div className="userm">
      <span className="loginb">
        <a href="/account/sign-up"><Button >{t("Εγγραφή")}</Button></a>
      </span>
      <span className="loginb1">
        <a href="/account/login"><Button className="btn1"> {t("Είσοδος")}</Button></a>
      </span>
      <span className="fa fa-globe fa-lg"></span>
      <select onChange={handleLangChange} value= {languageCtx.languageCode}>
        <option value="el">Ελληνικά</option>
        <option value="en">English</option>
        <option value="fr">Français</option>
        </select>
        </div>
      }
       {authCtx.isLoggedIn==true &&
      <div className="userm">
      <span className="loginb">
        <a href="/trip"><Button >{t("Τα ταξίδια μου")}</Button></a>
      </span>
      <span className="loginb1">
        <a href="/account/login"><Button className="btn1"> {t("Αποσύνδεση")}</Button></a>
      </span>
      <span className="fa fa-globe fa-lg"></span>
      <select onChange={handleLangChange} value= {languageCtx.languageCode}>
        <option value="el">Ελληνικά</option>
        <option value="en">English</option>
        <option value="fr">Français</option>
        </select>
        </div>
      }
    </nav>
      
 );
}
export default Header;  